@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

* {
  font-family: "Inter", sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-position: inside;
}
::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: #22c55e;
}
::selection {
  color: white;
  background: #22c55e;
}
.ant-input-prefix {
  margin-inline-end: 12px !important;
}
.ant-form-item {
  margin-bottom: 16px !important;
}
.ant-form-item-explain-error {
  font-size: 12px !important;
  margin-bottom: 16px;
}
.third-party-auth {
  border: 1px solid #d4d4d4;
  width: 100%;
  justify-content: left;
}
@media only screen and (max-width: 768px) {
  .hidden-mobile {
    display: none !important;
  }
  .ant-steps-item-tail {
    margin-inline: 0 !important;
    width: 100px !important;
  }
  .ant-steps-item-container,
  .ant-steps-item {
    max-width: 100px !important;
  }
  .ant-steps-horizontal .ant-steps-item:last-child .ant-steps-item-container {
    width: auto !important;
  }
  .ant-steps-horizontal .ant-steps-item:last-child {
    width: auto !important;
  }
  .ant-steps-horizontal {
    justify-content: center;
  }
  .ant-steps-item-icon {
    margin-inline-start: 0 !important;
  }
  .ant-steps-item-content {
    width: 0px !important;
  }
}
.services-list::before {
  content: "";
  background: url(/public/assets/Vector.png);
  display: block;
  height: 16px;
  width: 16px;
  background-repeat: no-repeat;
}
.ant-otp-input {
  height: 48px;
}
.role-title,
.discover-title {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #262626 !important;
}
.register-step-4-select .ant-select-selection-placeholder,
.register-step-4-select .ant-select-selection-item {
  font-size: 14px !important;
}
.season-input .ant-select-selection-placeholder {
  color: #262626;
}
.ant-layout-sider-zero-width-trigger-left {
  top: 100px !important;
  inset-inline-end: -62px !important;
  border-radius: 8px !important;
  border: 1px solid #d9d9d9 !important;
}
.ant-layout-sider-collapsed {
  padding: 0 !important;
}
.left-sidebar-menu li {
  margin: 0 !important;
}
.chat-segmented .ant-segmented-item-label {
  display: inline-flex;
  align-items: center;
  min-height: 36px !important;
}
.chat-segmented {
  border-radius: 8px !important;
}
.chat-segmented .ant-segmented-item-selected {
  border-radius: 6px !important;
}
.new-chat-btn {
  height: 44px !important;
  box-shadow: none !important;
}
.left-sidebar-menu .ant-menu-submenu-title {
  padding-left: 0 !important;
}
.left-sidebar-menu .ant-menu-item {
  padding: 12px !important;
}
.left-sidebar .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}
.farm-select-disabled {
  background: transparent !important;
  border: 1px solid #d9d9d9 !important;
}
.left-sidebar .ant-menu-title-content {
  display: block !important;
}
.farm-segmented .ant-segmented-item-label {
  display: inline-flex;
  align-items: center;
  min-height: 36px !important;
}
.farm-segmented {
  border-radius: 8px !important;
}
.farm-segmented .ant-segmented-item-selected {
  border-radius: 6px !important;
}
.edit-field-farm-btn {
  height: 44px !important;
  box-shadow: none !important;
}
.left-sidebar .ant-menu-item,
.right-sidebar .ant-menu-item {
  margin-bottom: 16px !important;
}
.left-sidebar .ant-menu-item:last-child,
.right-sidebar .ant-menu-item:last-child {
  margin-bottom: 0 !important;
}
.chat-drawer .ant-menu-item {
  margin-bottom: 16px !important;
}
.chat-drawer .ant-menu-item:last-child {
  margin-bottom: 0 !important;
}
.chat-drawer .ant-menu-title-content {
  display: block !important;
}
.chat-drawer .ant-menu-item {
  height: 48px !important;
}
.chat-drawer .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 12px !important;
}
.chat-drawer .ant-tabs-nav {
  margin: 0 0 20px 0;
}
.chat-drawer .ant-tabs.ant-tabs-top.ant-tabs-mobile.ant-tabs-large {
  height: 100% !important;
}
.chat-drawer .ant-tabs-tabpane.ant-tabs-tabpane-active {
  display: flex;
  flex-direction: column;
  height: 100% !important;
}
.chat-drawer .ant-tabs-content.ant-tabs-content-top {
  height: 100% !important;
}
.chat-drawer .edit-field-farm-btn {
  height: 48px !important;
  box-shadow: none !important;
  padding: 12px 16px !important;
}
.right-sidebar .ant-layout-sider-children {
  display: flex !important;
  flex-direction: column !important;
}
.col-right-sidebar .ant-collapse-content-box,
.col-right-sidebar .ant-collapse-header {
  padding: 12px 0 !important;
  overflow-x: hidden;
}
.ant-modal-title {
  font-size: 24px !important;
}
.ant-modal-footer {
  border-top: 1px solid #d4d4d4 !important;
  padding-top: 24px !important;
}
.chat-drawer .ant-drawer-header {
  padding: 20px !important;
  border-bottom: none !important;
}
.chat-drawer .ant-drawer-body {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
}
.chat-drawer .new-chat-btn {
  height: 48px !important;
  box-shadow: none !important;
  padding: 12px 16px !important;
}
.ant-modal .ant-modal-close {
  border-radius: 8px;
  border: 1px solid #d4d4d4;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  top: 38px;
  inset-inline-end: 24px;
}
.ant-modal .ant-modal-content {
  padding: 24px;
}
.select-chat-cart .ant-card-actions li:first-child {
  display: flex;
  justify-content: center;
}
.agteq-chat-logo img {
  object-fit: contain;
}
.profile-modal .ant-modal-close {
  top: 20px !important;
}
.profile-form .ant-form-item {
  margin-bottom: 20px !important;
}

.deactivate-account-btn:hover {
  color: #ef4444 !important;
  border: 1px solid #d4d4d4 !important;
  background-color: #fff !important;
}
.discard-profile-btn:hover {
  color: #262626 !important;
  border: 1px solid #d4d4d4 !important;
  background-color: #fff !important;
}

.markdown-content p {
  margin: 1em 0;
}

.markdown-content strong {
  font-weight: bold;
}

.markdown-content h2,
.markdown-content h3 {
  margin: 2em 0 1.5em;
  font-weight: bold;
  font-size: 1.2em;
}

.markdown-content ul {
  padding-left: 2em;
  list-style-type: disc;
}

.markdown-content ol {
  padding-left: 1.5em;
  list-style-type: decimal;
}

.markdown-content table {
  width: 100%;
  border-collapse: collapse;
  margin: 1em 0;
}

.markdown-content th,
.markdown-content td {
  border: 1px solid #ddd;
  padding: 0.5em;
}

.markdown-content th {
  font-weight: bold;
  background-color: #f5f5f5;
}

.delete-modal-btn:hover {
  color: #ef4444 !important;
  border: 1px solid #d4d4d4 !important;
  background-color: #fff !important;
}

.custom-confirm {
  height: 500px !important;
}
.custom-confirm .ant-modal-content {
  max-height: 600px; /* Set max height */
  overflow-y: auto; /* Add scroll if content overflows */
}

.custom-confirm .ant-btn-primary {
  background-color: #ef4444; /* "Delete" button background */
  color: #fff;
  border-color: #ef4444;
}

.custom-confirm .ant-btn-default {
  background-color: #fff; /* "Cancel" button background */
  color: #000;
  border-color: #d9d9d9;
}

.cancel-modal-btn:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border: 20px soid rgba(0, 0, 0, 0.3);
}

.chat-input {
  border-radius: 50px; /* Fully round both left and right sides */
}

.chat-input textarea {
  border-radius: 999px; /* Apply full rounding to the inner textarea */
}

.dots {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
  animation: dotsAnimation 1.3s steps(2, start) infinite;
}

.chat-card .ant-card-body {
  padding: 8px 0px;
}

.chat-user .ant-card-body {
  padding: 8px 24px;
}

@keyframes dotsAnimation {
  0% {
    width: 0px;
  }
  35% {
    width: 0px;
  }
  70% {
    width: 7px;
  }
  100% {
    width: 16px;
  }
}
